<template>
  <v-card class="mx-auto">
    <!--se forem rotas normais-->
    <v-select v-if="rotas"
      :items="rotas"
      v-model="rotaSelecionada"
      name="rotas"
      label="Rotas"
      single-line
      item-text="nome"
      item-value="id"
      hide-details
      solo>
    </v-select>
    <!--se for limpeza, monstros ou manuteção-->
    <v-select v-else
      :items="freguesias"
      v-model="freguesiaSelecionada"
      name="freguesias"
      label="Freguesias"
      single-line
      item-text="freguesia"
      item-value="id"
      hide-details
      solo>
        <template slot="selection" slot-scope="data">
          {{ data.item.freguesia }} ({{ data.item.count }})
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.freguesia }} ({{ data.item.count }})
        </template>
    </v-select>
    <template v-if="rotaSelecionada || freguesiaSelecionada">
      <v-col>
        <v-card @click="addContentor = true" :id="`cadd`" :color="'green'">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title style="color: white" class="headline">
              <v-avatar class="ma-3" size="30">
                <template>
                  <v-icon color="white  darken-2"> mdi-plus </v-icon>
                </template>
              </v-avatar>
              Adicionar contentor
            </v-card-title>
          </div>
        </v-card>
      </v-col>
      <v-dialog v-model="addContentor" max-width="320">
        <v-card class="px-2">
          <v-card-title class="headline">
            Novo Contentor
          </v-card-title>
          <v-card-subtitle>
            Será adicionado ao final do circuito
          </v-card-subtitle>
          <v-autocomplete
            v-model="novoContentor"
            :items="contentors"
            item-text="autocomplete"
            label="Contentores"
            class="mr-4"
            return-object
            required>
          </v-autocomplete>
          <v-card-text></v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              class="ma-2 white--text"
              @click="addNewContentor()">
              Adicionar
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
            <v-btn
              color="error"
              class="ma-2 white--text"
              @click="addContentor = false"
              text>
              Cancelar
              <v-icon right dark>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="rotas">
        <template v-for="rota in rotas">
          <template v-if="rota.id == rotaSelecionada">
            <v-col v-for="item in rota.Contentors" :key="item.id" :id="`c${item.contentor.id}`">
              <contentor-card
              :item="item"
              :openContentor="contentor"
              />
            </v-col>
          </template>
        </template>
      </div>
      <div v-if="freguesias">
        <v-col v-for="item in contentorsSelected" :key="item.id" :id="`c${item.id}`">
          <contentor-card
            :item="item"
            :openContentor="contentor"
          />
        </v-col>
      </div>
    </template>
  </v-card>
</template>

<script>
//import axios from "axios";

/* eslint-disable */
import ContentorCard from '../components/ContentorCard.vue';
import mixin from "../plugins/mixin";

export default {
  components: {
    ContentorCard,
  },
  mixins: [mixin],
  data: () => ({
    titulo: "Contentores",
    itens: [],
    rotas: [],
    rotastmp: [],
    contentors: [],  //all containers
    novoContentor: {},
    rotaSelecionada: 0,
    addContentor: false,
    /*************/
    contentorsFiltered: [], //containers that are filtered based on the route or other
    contentorsSelected: [], //containers tha in fact are shown
    freguesias: [],
    freguesiaSelecionada: -1,
    reports: [],

  }),
  methods: {
    corBarra(numero) {
      if (numero < 25) return "teal";
      else if (numero < 50) return "primary";
      else return "red";
    },
    contentor(item) {
      this.setObjeto("contentorSelecionado", item);
      this.$router.push("/contentor");
    },
    listContentores() {},
    goLogin() {
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");
      // localStorage.removeItem("rotas");
      // localStorage.removeItem("rotaSelecionada");
      // localStorage.removeItem("caminhao");
      // localStorage.removeItem("freguesias");
      // localStorage.removeItem("freguesiaSelecionada");
      // localStorage.removeItem("historicocircuitos");
      // localStorage.removeItem("contentorsFiltered");

      localStorage.clear();
      this.$router.push("/");
    },
    addNewContentor() {
      if (this.rotas) {
        this.rotas.forEach((item) => {
          if (item.id == this.rotaSelecionada) {
            item.Contentors.unshift({ contentor: this.novoContentor });
          }
        });
        this.setObjeto("rotas", this.rotas);
      } else {
        this.contentorsFiltered.unshift(this.novoContentor);
        this.setObjeto("contentorsFiltered", this.contentorsFiltered);
      }

      this.addContentor = false;
      this.$router.go(0);
    },
  },
  mounted() {
    if (this.$route.hash) {
      const el = document.querySelector(this.$route.hash);
      el && el.scrollIntoView();
    }
  },
  watch: {
    rotaSelecionada: function (to) {
      this.setObjeto("rotaSelecionada", to);
    },
    freguesiaSelecionada: function(to) {
      if (this.freguesias) {
        if (to == -1){
          this.contentorsSelected = this.contentorsFiltered;
        } else {
          var freguesia = this.freguesias.filter(freg => freg.id==to)[0].freguesia;
          this.contentorsSelected = this.contentorsFiltered.filter(cont => cont.localidade.designacao == freguesia);
        }
        this.setObjeto("freguesiaSelecionada", to);
        this.setObjeto("contentorsFiltered", this.contentorsFiltered);
      }
    }
  },
  created() {
    this.error = "";
    this.rotas = this.getObjeto("rotas");
    this.rotaSelecionada = this.getObjeto("rotaSelecionada");
    this.freguesias = this.getObjeto("freguesias");
    this.freguesiaSelecionada = this.getObjeto("freguesiaSelecionada");
    this.contentors = this.getObjeto("contentors");
    this.contentorsFiltered = this.contentorsSelected = this.getObjeto("contentorsFiltered");
    if (!this.rotas && !this.freguesias) this.goLogin();
  },
};
</script>

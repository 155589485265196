<template>
  <v-card
    :id="`c${item.id}`"
    @click="doReport"
    :color="report ? '#e2fee4' : ''">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <div v-if="item.contentor">
          <v-card-title class="headline">
            {{ item.contentor.numero_contentor }}
          </v-card-title>
          <v-card-subtitle>
            {{ item.contentor.rua + " - " + item.contentor.freguesia }}
            <br />
            {{ item.contentor.referencia }}<br />
            <v-alert v-if="item.obs" dense type="info">
              {{ item.obs }}
            </v-alert>
          </v-card-subtitle>
        </div>
        <div v-else>
          <v-card-title class="headline">
            {{ item.numero_contentor }}
          </v-card-title>
          <v-card-subtitle>
            {{ item.rua + " - " + item.localidade.designacao }}
            <br />
            {{ item.referencia }}<br />
            <v-alert v-if="item.obs" dense type="info">
              {{ item.obs }}
            </v-alert>
          </v-card-subtitle>
        </div>
        <p style="font-size: 13px"></p>
      </div>
      <v-avatar class="ma-3" size="80" tile>
        <template v-if="!report">
          <v-progress-circular
            tile
            v-if="item.sensor"
            :rotate="360"
            :size="70"
            :width="12"
            :value="item.nivel"
            :color="corBarra(item.nivel)"
            >{{ item.nivel }}%</v-progress-circular
          >
          <v-icon
            medium
            dark
            color="green  darken-2"
            tile
            v-if="!item.sensor"
            >mdi-pencil-plus</v-icon
          >
        </template>
        <template v-else>
          <v-icon
            medium
            dark
            color="orange darken-2"
            tile
            v-if="report.lixofora"
            >mdi-basket-unfill</v-icon
          >
          <v-icon
            medium
            dark
            color="green darken-2"
            tile
            v-if="
              report.recolheuLixo ||
              report.recolheuMostros ||
              report.fezHigenizacao ||
              report.fezManutencao
            "
            >mdi-check</v-icon
          >
          <v-icon
            medium
            dark
            color="black darken-2"
            tile
            v-if="report.tampaPartida"
            >mdi-delete-empty</v-icon
          >
          <v-icon
            medium
            dark
            color="red darken-2"
            tile
            v-if="report.temAvaria"
            >mdi-delete-forever</v-icon
          >
          <v-icon
            medium
            dark
            color="yellow darken-2"
            tile
            v-if="report.precisaLimpeza"
            >mdi-broom</v-icon
          >
          <v-icon
            medium
            dark
            color="blue darken-2"
            tile
            v-if="report.monstros"
            >mdi-seat</v-icon
          >
        </template>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ContentorCard',
  props: ['item', 'openContentor'],
  data: () => ({
    report: null,
  }),
  created() {
    if(this.item.report) {
      this.report = this.item.report;
    } else if (this.item.contentor && this.item.contentor.report) {
      this.report = this.item.contentor.report;
    } else {
      this.report = null;
    }
  },
  methods: {
    doReport(){
      // Diferentiates between rotas "normais" e rotas higienização/monstro/manutenção
      let obj = this.item.contentor ? this.item.contentor : this.item;
      this.openContentor({id: -1, obs: null, contentor: obj});
    }
  }
};
</script>

<style scoped>

</style>
